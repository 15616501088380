<template>
  <div
    v-if="canOrderAddressEdit"
    v-expose="{ id: '1-20-1-109', data: analysisParamsInfo }"
    class="address-edit_box"
    :class="{'mshe-fr': isIconScene }"
  >
    <div
      v-if="isIconScene"
      v-tap="{ id: '1-20-1-110', data: analysisParamsInfo }"
      :class="{'disabled-address_icon': disabledOrderAddressEdit }"
      class="address-edit"
      @click="handleEditAddressEvt"
    >
      <slot name="default"></slot>
    </div>
    <s-button
      v-else-if="isOrderListOrDetailEdit"
      v-tap="{ id: '1-20-1-110', data: analysisParamsInfo }"
      :type="buttonStyle"
      :class="{'disabled-address_edit': disabledOrderAddressEdit }"
      :disabled="disabled"
      class="order-address_edit order-list-bot__btn order-detail-footer__btn"
      @click="handleEditAddressEvt"
    >
      {{ showShort ? language.SHEIN_KEY_PWA_23929: language.SHEIN_KEY_PWA_16969 }}
    </s-button>
    <s-button
      v-else-if="isBrTaxNumber"
      v-tap="{ id: '1-20-1-127', data: brTaxAnalysisParamsInfo }"
      :type="['primary', 'H72PX']"
      :disabled="disabled"
      width="100%"
      @click="handleEditAddressEvt"
    >
      <slot name="default"></slot>
    </s-button>
    <s-button
      v-else
      v-tap="{ id: '1-20-1-110', data: analysisParamsInfo }"
      :type="buttonStyle"
      :disabled="disabled"
      @click="handleEditAddressEvt"
    >
      <slot name="default"></slot>
    </s-button>
    <!-- 置灰点击提示 -->
    <LazyMount>
      <CommonTipsDialog
        :visible.sync="disabledOrderAddressEditDialog"
        append-to-body
        :content="grayCodeText"
        :btn-type="['primary', 'H56PX']"
        :btn-text="language.SHEIN_KEY_PWA_15146"
        @ok="disabledOrderAddressEditDialog = false"
      />
    </LazyMount>
    <!-- 分发货前/发货后提示 -->
    <LazyMount>
      <CommonTipsDialog
        :visible.sync="canOrderAddressEditDialog"
        append-to-body
        :content="canOrderAddressEditText"
        :btn-type="['primary', 'H56PX']"
        :btn-text="language.SHEIN_KEY_PWA_15146"
        @ok="canOrderAddressEditEvt"
      />
    </LazyMount>
    <!-- 批量修改地址确认弹窗 -->
    <LazyMount>
      <EditAddressConfirmDialog
        :visible.sync="showEditAddressConfirmDialog" 
        :language="language"
        :batch-modify-address="batchModifyAddressData"
        :billno="order.billno"
        @confirm="onConfirmModifyAddress"
      />
    </LazyMount>
  </div>
</template>
<script>
import { tap, expose } from 'public/src/pages/common/analysis/directive.js'
export default {
  directives: { tap, expose }
}
</script>
<script setup>
import { ref, computed } from 'vue'
import { TRANSPORT_TYPE_SHOP } from 'public/src/js/constants'
import orderLogic from 'public/src/pages/common/orderLogic/index'
import CommonTipsDialog from 'public/src/pages/user/child_pages/orders/common/commonTipsDialog'
import EditAddressConfirmDialog from 'public/src/pages/user/child_pages/orders/common/edit-address/EditAddressConfirmDialog'
import { getBatchEditAddressInfo, isRelationBillno } from 'public/src/pages/user/child_pages/orders/common/orderAddressNewTools'
import { getDisabledConfig, getExtraConfig, initShipAddressConfig } from 'public/src/pages/trackNew/utils'
import { Toast as $toast, LazyMount } from '@shein/sui-mobile'

let { UPDATE_SHIPPED_ADDRESS } = typeof window != 'undefined' ? gbCommonInfo : {}
let props = defineProps({
  editAddressNewConfigList: {
    type: Array,
    default() {
      return []
    }
  },
  order: {
    type: Object,
    default() {
      return {}
    }
  },
  language: {
    type: Object,
    default() {
      return {}
    }
  },
  newEditAddressOptions: {
    type: Object,
    default() {
      return {}
    }
  },
  buttonStyle: {
    type: Array,
    default() {
      return ['H56PX']
    }
  },
  showShort: {
    type: Boolean,
    default: false
  },
  isOnline: {
    type: Boolean,
    default: true
  },
  disabled: {
    type: Boolean,
    default: false
  }
})

let emit = defineEmits([
  'showEditAddressDialog'
])
let order = computed(() => {
  return props.order || {}
})
let language = computed(() => {
  return props.language || {}
})
let newEditAddressOptions = computed(() => {
  return props.newEditAddressOptions || {}
})
let editAddressNewConfig = computed(() => {
  return orderLogic.getEditAddressConfig(props.editAddressNewConfigList, props.order?.billno) || {}
})

let isIconScene = computed(() => {
  return newEditAddressOptions.value?.btnSource == 'Icon'
})
// 订单列表页或者订单详情页特殊场景处理
let isOrderListOrDetailEdit = computed(() => {
  let pageSource = newEditAddressOptions.value?.pageSource || ''
  return pageSource == 'OrderList' || pageSource == 'OrderDetail'
})
// 支付成功页特殊场景处理
let isPaySuccessUseThis = computed(() => {
  let pageSource = newEditAddressOptions.value?.pageSource || ''
  let btnSource = newEditAddressOptions.value?.btnSource || ''
  return pageSource == 'PaySuccess' && btnSource == 'UseThis'
})

const isPaySuccessPage = computed(() => {
  const pageSource = newEditAddressOptions.value?.pageSource || ''
  return pageSource == 'PaySuccess'
})

// 巴西税号特殊场景处理
let isBrTaxNumber = computed(() => {
  let btnSource = newEditAddressOptions.value?.btnSource || ''
  return btnSource == 'brTaxNumber'
})

let brTaxAnalysisParamsInfo = computed(() => {
  return {
    country_name: order.value?.shipping_country_name || '',
    scence_type: newEditAddressOptions?.value?.recomondAddressScenceType || ''
  }
})

let canOrderAddressEdit = computed(() => {
  if (isOrderListOrDetailEdit.value) {
    return [1, 2].includes(+editAddressNewConfig.value?.is_order_address_editable)
  }
  return [1].includes(+editAddressNewConfig.value?.is_order_address_editable)
})
let disabledOrderAddressEdit = computed(() => {
  return [2].includes(+editAddressNewConfig.value?.is_order_address_editable)
})

let disabledOrderAddressEditDialog = ref(false)

let showEditAddressConfirmDialog = ref(false)
let batchModifyAddressData = ref({})

let disabledAddress = ref(['country'])
let extraAddress = ref(['isDefault', 'type', 'certificateTw'])

let canEditableTypeList = ['all_shipped', 'partly_shipped_after_editable']
let cannotEditableTypeList = ['unpaid', 'all_shipped', 'partly_shipped_after_editable']
let canOrderAddressEditDialog = ref(false)
let canOrderAddressEditText = ref('')

let grayCodeText = computed(() => {
  let gray_code = editAddressNewConfig.value?.gray_code
  let grayCodeMap = {
    1: 'SHEIN_KEY_PWA_21411',
    2: 'SHEIN_KEY_PWA_21420',
    3: 'SHEIN_KEY_PWA_21411',
    4: 'SHEIN_KEY_PWA_21411',
    5: 'SHEIN_KEY_PWA_21411'
  }
  return grayCodeMap[gray_code] ? language.value?.[grayCodeMap[gray_code]] : ''
})

let analysisParamsInfo = computed(() => {
  let pageSource = newEditAddressOptions.value?.pageSource || ''
  let baseInfo = {
    order_no: editAddressNewConfig.value?.billno || '',
    editable_type: editAddressNewConfig.value?.editable_type || '',
    is_order_address_editable: editAddressNewConfig.value?.is_order_address_editable || ''
  }
  return pageSource == 'OrderDetail' ? {
    ...baseInfo,
    button_position: isIconScene.value ? 'address_module' : 'footer'
  } : baseInfo
})

let reqBillno = computed(() => {
  return isRelationBillno(editAddressNewConfig.value)
})

let handleEditAddressEvt = async () => {
  if (!props.isOnline || !window.navigator.onLine) {
    $toast(gbCommonInfo?.language?.SHEIN_KEY_PWA_31762 || language.value?.SHEIN_KEY_PWA_31762)
    return
  }
  // 禁用点击提示文案
  if (disabledOrderAddressEdit.value) {
    disabledOrderAddressEditDialog.value = !!grayCodeText.value
    return
  }
  
  let editable_type = editAddressNewConfig.value?.editable_type
  let combined_shipped_flag = editAddressNewConfig.value?.combined_shipped_flag
  let batchModifyAddress = newEditAddressOptions.value?.batchModifyAddress
  // 允许编辑地址[发货后]
  if (canEditableTypeList.includes(editable_type)) {
    if (isPaySuccessUseThis.value) {
      // 不经过地址编辑页, 直接调用接口修改地址
      toForceEditAddressEvt()
    } else {
      let languageMap = {
        1: 'SHEIN_KEY_PWA_21421',
        default: 'SHEIN_KEY_PWA_21422'
      }
      let languageKey = languageMap[combined_shipped_flag] || languageMap['default']
      canOrderAddressEditText.value = language.value?.[languageKey]
      canOrderAddressEditDialog.value = true
    }
    return
  }

  // 允许编辑地址[发货前]
  if (!cannotEditableTypeList.includes(editable_type)) {
    // batchModifyAddress开关关闭
    if (!batchModifyAddress) {
      if (isPaySuccessUseThis.value) {
        toForceEditAddressEvt()
      } else {
        toEditAddressEvt()
      }
      return 
    }
    
    // 请求批量修改地址接口
    let pageSource = newEditAddressOptions.value?.pageSource || ''
    let btnSource = newEditAddressOptions.value?.btnSource || ''
    let resInfo = await getBatchEditAddressInfo({
      billno: reqBillno.value,
      sceneType: `${pageSource}_${btnSource}`,
      orderStatus: order.value?.orderStatus
    })
    let {
      modify_type = '0',
      relation_billno_list = [],
      merge_billno_list = []
    } = resInfo || {}
    if (modify_type == '0') {
      if (isPaySuccessUseThis.value) {
        toForceEditAddressEvt()
      } else {
        toEditAddressEvt()
      }
      return
    }

    if (modify_type == '1') {
      if (!isPaySuccessPage.value) {
        // 出批量修改弹框
        batchModifyAddressData.value = resInfo || {}
        showEditAddressConfirmDialog.value = true
      } else {
        let isSpecialCond = !!relation_billno_list?.length && !merge_billno_list?.length
        if (isSpecialCond) {
          if (isPaySuccessUseThis.value) {
            toForceEditAddressEvt({
              batchModifyAddressParams: {
                modify_type: 1
              }
            })
          } else {
            toEditAddressEvt({
              batchModifyAddressParams: {
                modify_type: 1
              }
            })
          }
        } else {
          // 出批量修改弹框
          batchModifyAddressData.value = resInfo || {}
          showEditAddressConfirmDialog.value = true
        }
      }
      return
    }

    batchModifyAddressData.value = resInfo || {}
    showEditAddressConfirmDialog.value = true
    return
  }

  if (isPaySuccessUseThis.value) {
    toForceEditAddressEvt()
  } else {
    toEditAddressEvt()
  }
}

let onConfirmModifyAddress = (crossType) => {
  if (isPaySuccessUseThis.value) {
    toForceEditAddressEvt({
      batchModifyAddressParams: {
        cross_type: crossType,
        modify_type: batchModifyAddressData.value?.modify_type
      }
    })
  } else {
    toEditAddressEvt({
      batchModifyAddressParams: {
        cross_type: crossType,
        modify_type: batchModifyAddressData.value?.modify_type
      }
    })
  }
}

let canOrderAddressEditEvt = () => {
  canOrderAddressEditDialog.value = false
  toDisabledEditAddressEvt()
}

// 强制触发编辑地址接口, 不用出地址弹框
let toForceEditAddressEvt = ({ batchModifyAddressParams = {} } = {}) => {
  console.log('toForceEditAddressEvt=======')
  let shipInfo = initShipAddressConfig({ order: order.value || {} })
  let { transport_type = '', store_id = '', subsidiary = {} } = order.value || {}
  transport_type = transport_type || subsidiary?.transport_type || ''
  store_id = store_id || subsidiary?.store_id || ''
  if (TRANSPORT_TYPE_SHOP.includes(transport_type) && store_id) {
    let extraData = orderLogic.getStoreAddressExtraData({ ...(order.value || {}), addTime: order.value?.add_time }) || {}
    emit('showEditAddressDialog', {
      isShop: true,
      forceUpdateAddress: true,
      addressInfo: shipInfo,
      orderInfo: order.value,
      options: {
        type: 'edit',
        addrData: {
          ...shipInfo,
          editAddressFlag: true
        },
        url: '/api/checkout/editShipping/update?billno=' + reqBillno.value,
        ajaxType: 'post',
        countryId: shipInfo.countryId,
        extra: ['certificateTw'],
        disabled: disabledAddress.value,
        extraParams: batchModifyAddressParams || {},
        extraData: {
          ...extraData,
          billno: reqBillno.value
        }
      }
    })
  } else {
    emit('showEditAddressDialog', {
      isShop: false,
      forceUpdateAddress: true,
      addressInfo: shipInfo,
      orderInfo: order.value,
      options: {
        disabled: disabledAddress.value,
        extra: extraAddress.value,
        extraParams: batchModifyAddressParams || {}
      }
    })
  }
}

// 正常打开地址编辑页
let toEditAddressEvt = ({ batchModifyAddressParams = {} } = {}) => {
  console.log('toEditAddressEvt=======')
  let shipInfo = initShipAddressConfig({ order: order.value || {} })
  let { transport_type = '', store_id = '', subsidiary = {} } = order.value || {}
  transport_type = transport_type || subsidiary?.transport_type || ''
  store_id = store_id || subsidiary?.store_id || ''
  if (TRANSPORT_TYPE_SHOP.includes(transport_type) && store_id) {
    let extraData = orderLogic.getStoreAddressExtraData({ ...(order.value || {}), addTime: order.value?.add_time }) || {}
    emit('showEditAddressDialog', {
      isShop: true,
      forceUpdateAddress: false,
      addressInfo: shipInfo,
      orderInfo: order.value,
      options: {
        type: 'edit',
        addrData: {
          ...shipInfo,
          editAddressFlag: true
        },
        url: '/api/checkout/editShipping/update?billno=' + reqBillno.value,
        ajaxType: 'post',
        countryId: shipInfo.countryId,
        extra: ['certificateTw'],
        disabled: disabledAddress.value,
        extraParams: batchModifyAddressParams || {},
        extraData: {
          ...extraData,
          billno: reqBillno.value
        }
      }
    })
  } else {
    emit('showEditAddressDialog', {
      isShop: false,
      forceUpdateAddress: false,
      addressInfo: shipInfo,
      orderInfo: order.value,
      options: {
        disabled: disabledAddress.value,
        extra: extraAddress.value,
        extraParams: batchModifyAddressParams || {}
      }
    })
  }
}

let toDisabledEditAddressEvt = () => {
  // 获取最新的地址信息
  let shipInfo = initShipAddressConfig({ order: order.value || {} })

  // 获取置灰地址字段, 携带置灰字段调出编辑地址弹框
  let { transport_type = '', store_id = '', payment_method, orderStatus, subsidiary = {} } = order.value || {}
  transport_type = transport_type || subsidiary?.transport_type || ''
  store_id = store_id || subsidiary?.store_id || ''
  // 编辑地址前置状态设置
  let disabledAddressArr = getDisabledConfig({
    orderInfo: order.value,
    TRANSPORT_TYPE_SHOP,
    updateShippedAddress: UPDATE_SHIPPED_ADDRESS?.[0] || {},
    disabledAddress: disabledAddress.value
  })
  disabledAddress.value = disabledAddressArr?.length ? disabledAddressArr : disabledAddress.value
  if (payment_method === 'cod' && [6, 4].includes(+orderStatus)) {
    disabledAddress.value = [...new Set([...disabledAddress.value, 'tel', 'standbyTel'])]
  }
  extraAddress.value = getExtraConfig({ shipInfo })
  
  // 是否需要地址联想
  let needAssociatedAddress = disabledAddress.value?.length && disabledAddress.value?.some(item => !(['country', 'tel', 'standbyTel'].includes(item)))
  extraAddress.value = needAssociatedAddress ? [...extraAddress.value, 'associated_address'] : extraAddress.value

  if (TRANSPORT_TYPE_SHOP.includes(transport_type) && store_id) {
    let extraData = orderLogic.getStoreAddressExtraData({ ...(order.value || {}), addTime: order.value?.add_time }) || {}
    emit('showEditAddressDialog', {
      isShop: true,
      forceUpdateAddress: false,
      addressInfo: shipInfo,
      orderInfo: order.value,
      options: {
        type: 'edit',
        addrData: {
          ...shipInfo,
          editAddressFlag: true
        },
        url: '/api/checkout/editShipping/update?billno=' + reqBillno.value,
        ajaxType: 'post',
        countryId: shipInfo.countryId,
        extra: ['certificateTw'],
        disabled: disabledAddress.value,
        extraParams: {},
        extraData: {
          ...extraData,
          billno: reqBillno.value
        }
      }
    })
  } else {
    emit('showEditAddressDialog', {
      isShop: false,
      forceUpdateAddress: false,
      addressInfo: shipInfo,
      orderInfo: order.value,
      options: {
        disabled: disabledAddress.value,
        extra: extraAddress.value,
        extraParams: {}
      }
    })
  }
}

defineExpose({
  handleEditAddressEvt
})

</script>
<style lang="less">
.address-edit_box {
  display: inline-block;
  &.mshe-fr{
    float: right !important; /* stylelint-disable-line declaration-no-important */
  }
  .order-address_edit{
    .margin-l(.32rem);
  }
}
.disabled-address_icon {
  i {
    color: #ccc !important; /* stylelint-disable-line declaration-no-important */
  }
}
.disabled-address_edit {
  color: #bbb !important; /* stylelint-disable-line declaration-no-important */
  background: #999 !important; /* stylelint-disable-line declaration-no-important */
  border: none;
}
</style>
